.form-group-required .control-label:after { 
    color: #d00;
    content: "*";
    margin-left: 8px;
}

.navbar-custom {
    background-color: #ffffff;
    margin: -22px 0px 25px 0px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-50 {
    margin-left: 50px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.test-mode-bottom {
    border-bottom: 2px solid blue;
}

.live-mode-bottom {
    border-bottom: 2px solid green;
}

.add-token-btn{
    position: absolute;
    right: 10px;
    top: 10px;
}

.sidebar-dark .nav-item .nav-link,
.sidebar-dark .nav-item .nav-link:hover,
.sidebar-dark .nav-item .nav-link:active {
    color: #262626;
}

.sidebar-dark .nav-item .nav-link i {
    color: #262626;
}

.sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
    color: #262626;
}

.sidebar-dark hr.sidebar-divider {
    border-top: 1px solid #262626;
}

.sidebar .nav-item {
    background-color: #e4e4e4;
}

.nav-item-active {
    background-color: #4e73df !important;
}

.sidebar-dark .nav-item-active .nav-link,
.sidebar-dark .nav-item-active .nav-link:hover,
.sidebar-dark .nav-item-active .nav-link:active {
    color: #ffffff;
}

.sortable-item-list {
    position: relative;
    z-index: 0;
    border: 1px solid #efefef;
    border-radius: 3px;
    outline: none;

    .item {
        position: relative;
        border-bottom: 1px solid #999;
    }

    .stylized_item {
        height: 60px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        background-color: #fff;
        border-bottom: 1px solid #efefef;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #333;
        font-weight: 400;
    }

    .selector {
        position: relative;
        top: 1px;
        display: block;
        width: 18px;
        height: 11px;
        opacity: .25;
        margin-right: 20px;
        cursor: row-resize;
        background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
        background: linear-gradient(
    180deg
    ,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    }

    .btn-position {
        position: absolute;
        right: 10px;
    }

}

.chart-style {
    width: 100% !important;
    height: auto !important;
}

.custom-number-field {
    width: 90px;
}

// .mdb-dataTable tr:nth-child(odd){
//     background-color: #f9f9f9;
//     color: #262626;
// }